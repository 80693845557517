<template>
  <div class="relative grid grid-cols-1 h-48">
    <div class="bg-agrogo-green-2 h-6 md:bg-agrogo-white">
      <div class="absolute inset-x-0 top-0 space-x-6">
        <button type="button" class="btn-pujar">
          <div class="flex items-center justify-center">
            Pujar <span class="icon-hammer text-2xl pl-1"></span>
          </div>
        </button>
        <button type="button" class="btn-leave">Abandonar</button>
      </div>
    </div>
    <div class="bg-agrogo-black-1 pt-16 pb-4">
      <div class="flex items-center justify-center mb-9">
        <p
          class="font-dinpro font-bold text-base text-agrogo-green-6 pr-4 md:pr-8 lg:pr-12 xl:pr-14"
        >
          Finaliza <span class="text-agrogo-gray-1 font-normal">en:</span>
        </p>
        <p class="font-nexa font-black text-white text-lg md:text-2xl">
          <span
            class="icon-punto text-agrogo-green-6 text-sm md:text-base"
          ></span>
          07<span class="font-normal text-xs md:text-sm">m</span> 18<span
            class="font-normal text-xs md:text-sm"
            >s</span
          >
        </p>
      </div>
      <div class="flex items-center justify-center">
        <p class="font-dinpro font-normal text-sm text-agrogo-gray-1 pr-2">
          Ver <span class="text-medium"> Histórico </span> de Pujas (8)
        </p>
        <button @click="isHistory = true" type="button" class="btn-history">
          <!-- <span class="text-agrogo-green-5 icon-history text-xl"></span> -->
          <img
            class="bg-cover"
            src="@/assets/images/icondocument.svg"
            alt="icono documento"
          />
        </button>
      </div>
    </div>
  </div>
  <div class="h-1 bg-agrogo-green-3"></div>
  <history-of-auction
    :isHistory="isHistory"
    @closeModalHistory="isHistory = false"
  />
</template>

<script>
import HistoryOfAuction from "./HistoryOfAuction";
export default {
  components: {
    HistoryOfAuction,
  },
  data() {
    return {
      isHistory: false,
    };
  },
};
</script>
